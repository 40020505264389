interface ILocalStorageItem<T> {
	value: T;
	expiresOn: Date;
}
export class LocalStorageWithExpiry {
	/**
	 * Sets the data in localStorage with the key and time to live
	 * @param key key to store the data
	 * @param value value to store
	 * @param ttl time to live in milliseconds
	 */
	static setItem<T>(key: string, value: T, expiresOn: Date) {
		const now = new Date();
		const item: ILocalStorageItem<T> = {
			value: value,
			expiresOn: expiresOn,
		};
		localStorage.setItem(key, JSON.stringify(item));
	}

	/**
	 *
	 * @param key key to get the data
	 * @returns data if avaialble or has not expired, else null
	 */
	static getItem<T>(key: string): T | null {
		// getting the data from localStorage using the key
		var jsonStringValue = window.localStorage.getItem(key);
		if (jsonStringValue) {
			let result: ILocalStorageItem<T>;
			result = JSON.parse(jsonStringValue) as ILocalStorageItem<T>;
			if (new Date(result.expiresOn) <= new Date()) {
				window.localStorage.removeItem(key);
				return null;
			} else {
				return result.value;
			}
		}
    return null;
	}
}
