import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject, timer} from 'rxjs';
interface ToastTriggers {
  warning: ElementRef<HTMLElement>;
  success: ElementRef<HTMLElement>;
  error: ElementRef<HTMLElement>;
}
interface ToastMessage {
  message: string;
  type: string;
}
@Injectable({
  providedIn: 'root'
})
export class ToastrService {
  private toastTriggers!: ToastTriggers;
  private toastMessage = new BehaviorSubject<ToastMessage>({ message: '', type: ''});
  private duration= new BehaviorSubject<number>(5000);
  public init(toastTriggers: ToastTriggers) {
    this.toastTriggers = toastTriggers;
  }

  public showToastr(message: string, type: keyof ToastTriggers, duration=5000) {
    if (this.toastTriggers[type]) {
      this.toastMessage.next({ message, type });
      this.duration.next(duration);
      timer(10).subscribe(() => {
        this.toastTriggers[type].nativeElement.click();
      })
 // Set the message and type
} else {
console.error(`No toast trigger found for type: ${type}`);
}
  }
  /*public closeToast(type: keyof ToastTriggers) {
    if (this.toastTriggers[type]) {
      // Programmatically click the trigger to close the toast
      this.toastTriggers[type].nativeElement.click(); 
    }
  }*/
  /*public closeToast() {
    this.toastMessage.next({message: '', type: 'warning'}); // Clear the message
  }*/

  public getToastMessage(): BehaviorSubject<ToastMessage> {
    return this.toastMessage;
  }
  public getToastDuration(): BehaviorSubject<number> {
    return this.duration;
  }
  /*public closeToast() {
    // Logic to close the toast, typically when the close button is clicked
    this.toastMessage.next({ message: '', type: ''});
  }*/
  }
